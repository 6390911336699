.account-input {
    margin-top: 10px;

    .MuiInputBase-root {
        color: unset !important;
        border: 0;
    }

    .account-label {
        font-size: 14px;

        @media screen and (max-width: 720px) {
            font-size: 12px;
        }
    }

    .deposit-image {
        width: 100%;
    }

    .acc-input {
        border: 0;
        background-color: #eee;
        border-radius: 10px;
        padding: 14px;
        min-width: 100%;

        @media screen and (max-width: 720px) {
            font-size: 12px;
            position: relative;
        }

        &.MuiFormControl-root {
            padding: 0;

            .MuiInputBase-root {
                &::before {
                    border-bottom: 0;
                }

                .MuiInputBase-input {
                    font-size: 15px;
                    font-family: 'Montserrat';

                    @media screen and (max-width: 720px) {
                        font-size: 12px;
                        color: var(--color-black);
                    }
                }
            }
        }
    }
}

.select-template {
    display: flex;
    justify-content: left;
    align-items: center;

    .st-label {
        text-wrap: nowrap;
        // color: var(--custom-btn-bg-variant-1);
        // background: var(--ion-button-color);
        font-family: var(--headers-font-family);
        font-size: 16px !important;
        font-weight: 800 !important;
        line-height: 16.2px;
        text-align: center;

        @media screen and (max-width: 720px) {
            font-family: var(--headers-font-family);
            font-size: 12px;
            font-weight: 400;
            line-height: 16.2px;
            text-align: left;
            padding-left: 5px;
        }
    }

    .MuiInputBase-root {
        width: 155px;
        height: 30px;
        border: 1px solid white;
        border-radius: 20px;
        margin: 10px;
        background-color: #eee;

        .Mui-disabled {
            color: rgb(161, 157, 157) !important;
        }

        .MuiSelect-root {
            color: inherit;
            font-family: var(--headers-font-family);
            font-size: 12px;
            font-weight: 500;
            line-height: 16.2px;
        }

        .MuiSvgIcon-root {
            color: #fff;
        }
    }

    .MuiInput-underline::before,
    .MuiInput-underline::after {
        border-bottom: 0px;
    }

    .MuiInput-underline:hover {
        border-bottom: 2px solid #fff;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
    }
}