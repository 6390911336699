.forgot-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--ion-primary-gradient);

  .logo {
    margin-left: 30%;
    height: 50%;
    width: 40%;
  }

  .select-input {
    display: flex;
    justify-content: normal !important;
  }

  .usr-input,
  .code-input,
  .pwd-input {
    display: inline-grid;
  }

  .confirm-btn {
    margin-top: 0px !important;
    max-width: unset;
    height: 48px;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }

  .MuiIconButton-root {
    padding: 0;
    opacity: 1;
    color: var(--ion-text-color) !important;
  }

  .error-msg {
    color: var(--ion-color-danger);
    margin-top: 20px;
    text-align: center;
  }
  .success-msg {
    color: var(--ion-color-success);
    margin-top: 20px;
    text-align: center;
  }

  .msg-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .msg {
      margin-top: 4px;
      text-align: center;
      color: var(--ion-color-primary);
      font-size: 13px;
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .usr-input,
  .pwd-input,
  .code-input {
    margin-top: 25px;
  }

  .special-label{
    display: none;
  }

  .text-field {
    width: 47vh;
    margin-left: 10px;
  }

  .number-field {
    cursor: pointer;
    position: relative;
    background: none;
    // background-color: #000000;
    border-color: #D9A214;
  }
  
  .number-field,
  .text-field,
  .user-name,
  .pwd-field {
    border-radius: 10px;
    max-width: unset;
    height: 48px;
  }

  .sign-in-title {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: var(--text);
    margin-bottom: 10px;

    @media screen and (max-width: 720px) {
      font-size: 20px;
      .form-ctn {
        width: 90vw;
        min-height: 65vh;
      }
      .number-field,
      .text-field,
      .user-name,
      .pwd-field,
      .login-form-btn {
        max-width: 100% !important;
      }
    }
  }
}.option-selection{
  height: 45px;
  width: 38%;
  background: none;
  // background-color: #000000;
  border-radius: 10px;
}.form-control {
  height: 46px !important;
  width: 100%  !important;
}
@media (min-width: 320px) and (max-width: 520px) {
  .form-control {
  max-width: 100% !important;
  }
}
@media (min-width: 350px) and (max-width: 420px) {
  .number-field,
  .text-field,
  .user-name,
  .pwd-field,
  .login-form-btn {
    width: 100% !important;
  }
}
.send-btn {
  color: #000 !important;
  width: 36%;
  height: 30px !important;
  margin-left: 65% !important;
  margin-top: -3% !important;
}
.reset-btn{
  color: #000 !important;
  margin-bottom: 1% !important;
}
.back-home {
  margin-left: 40%;
}

// @media (min-width: 350px) and (max-width: 420px) {
//   .user-name,
//   .pwd-field,
//   .login-form-btn {
//     width: 298px !important;
//   }
// }

// @media (min-width: 220px) and (max-width: 349px) {
//   .user-name,
//   .pwd-field,
//   .login-form-btn {
//     max-width: 282px !important;
//   }
// }
