.home-page-ctn {
  .page-banner-ctn {
    @media screen and (min-width: 720px) {
      padding: 0 18px;
    }

    .slick-slider .slick-track {
      @media screen and (max-width: 720px) {
        height: auto;
      }
    }

    .slick-dots li button::before {
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
    }

    .slick-dots li.slick-active button::before {
      color: var(--button);
      box-shadow: 0px 2px 6px rgba(236, 52, 81, 0.6);
    }

    .slick-dots {
      background-color: #000000;
      bottom: 20px;
      text-align: end;
      display: flex !important;
      justify-content: end;
      width: fit-content;
      position: absolute;
      right: 14px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 20px;
      padding: 0 9px;

      @media screen and (max-width: 720px) {
        bottom: 14px;
        right: 12px;
      }

      li {
        margin: 0;
        width: 14px;

        button {
          width: 14px;

          ::before {
            width: 14px;
          }
        }
      }

      li button::before {
        color: rgba(255, 255, 255, 0.4);
        width: 14px;
      }
    }

    .slider-images {
      height: 350px;
      @media screen and (max-width: 1440px) {
          height: 450px;
      }

      @media screen and (max-width: 720px) {
        height: auto;
        margin: 0px;
        width: 100%;
      }

      .banner-images {
        height: 350px;
        width: 100%;
        @media screen and (max-width: 1440px) {
          height: 450px;
      }

        @media screen and (max-width: 720px) {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .home-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 50px;
    position: relative;
    background-color: var(--background-image-color);
    border-radius: 35px 35px 10px 10px;
    margin: 0 18px 0 18px;

    .background-img {
      position: absolute;
      height: 100%;
      opacity: .4;
    }

    @media screen and (max-width: 720px) {
      padding-left: 7px;
      padding-right: 7px;
      margin: 0;
      margin-top: 16px;
      padding-bottom: 120px;
      // width: 100%;
    }

    .dc-ion-col {
      padding: 0 7px 12px 7px;
      margin-bottom: 16px;

      @media screen and (max-width: 720px) {
        padding: 5px 4px 5px 4px;
        margin-bottom: 0px;
      }
    }

    .MuiTypography-body1 {
      @media screen and (max-width: 720px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .card-slider {
      height: 240px;
      width: 100%;
      background-color: var(--headerbg);
      margin-bottom: 10px;
      border-radius: 8px;

      .card-header {
        height: 40px;
        background-color: var(--bg-active);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        font-weight: bold;
        text-transform: uppercase;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;

        .title {
          font-size: 16px;
          text-transform: uppercase;
          margin-left: 10px;

        }

        .slider-btn {
          display: flex;
          flex-direction: row;
          cursor: pointer;
        }
      }

      .card-images {
        height: 200px;
        width: 100%;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        object-fit: cover;
      }
    }

    .MuiTabs-flexContainer,
    .game-types-tabs {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;

      .card {
        cursor: pointer;
        margin-left: 4px;
        margin-right: 4px;
        text-decoration: none;

        &.selected {
          border: 2px solid var(--bg-active);
        }

        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        height: 85px;
        border-radius: 15px;

        @media screen and (max-width: 720px) {
          height: 70px;
          min-width: 80px !important;
          border-radius: 10px;
          margin-left: 10px;
          margin-right: 10px;
        }

        background: var(--headerbg);
        text-transform: uppercase;

        .label-ctn {
          position: absolute;
          bottom: 15px;
          display: flex;
          justify-content: center;
          text-align: center !important;
          width: 100%;
          font-size: 14px;

          @media screen and (max-width: 720px) {
            font-size: 10px;
          }

          .label {
            color: var(--text);
            font-size: 14px;
            text-transform: uppercase;
            text-align: center !important;
            font-weight: 400;

            @media screen and (max-width: 720px) {
              font-size: 10px;
            }
          }
        }

        .img-ctn {
          display: block;
          height: 100%;
          width: 100%;
          margin-top: -20px;

          img {
            image-rendering: -moz-crisp-edges !important;
            image-rendering: -o-crisp-edges !important;
            image-rendering: -webkit-optimize-contrast !important;
            image-rendering: pixelated !important;
            -ms-interpolation-mode: nearest-neighbor !important;
          }

          .icon1 {
            height: 30px;
          }

          .icon2 {
            height: 50px;
          }

          display: flex;
          justify-content: center;
          align-items: center !important;
        }
      }
    }
  }

  .home-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: var(--background-image-opacity);
    background-image: url('../../assets/images/home/homeview/background.svg');
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }

  .search-bar {
    display: flex;
    flex: 1 1 1;
    width: 100%;
    // padding-left: 20px;
    // padding-right: 20px;
    position: relative;
    top: -10px;

    @media screen and (max-width: 720px) {
      display: block;
      padding-left: 12px;
      padding-right: 12px;
      position: relative;
      top: -20px;
    }

    .search {
      position: relative;
      width: 100%;

      @media screen and (max-width: 720px) {
        width: 100%;
        margin-bottom: 10px;
      }

      // background: #242F39;
      // border: 1px solid #454646;

      .searchButton {
        right: 22.4px;
        position: absolute;
        top: 17px;
        cursor: pointer;

        @media screen and (max-width: 720px) {
          top: 11px;
          right: 14px;
        }
      }
    }

    .input {
      width: 100%;
      height: 55px;
      background: #242F39;
      border: 1px solid #454646;
      color: white;
      padding-left: 12px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;

      background: linear-gradient(180deg, #161D23 0%, rgba(65, 65, 65, 0.96) 100%);
      border: 2px solid #000000;
      border-radius: 12px;

      @media screen and (max-width: 720px) {
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 4px;
      }
    }

    .second-casino-filter {
      .select-filter {
        border-radius: 0 12px 12px 0;

        @media screen and (max-width: 720px) {
          border-radius: 4px;
        }
      }

    }

    .casino-filters-row {
      width: 33.33%;

      .select-filter {
        width: 100%;
        height: 55px;
        padding-left: 12px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(180deg, #161D23 0%, rgba(65, 65, 65, 0.96) 100%);
        border: 2px solid #000000;


        @media screen and (max-width: 720px) {
          height: 40px;
          font-size: 12px;
          line-height: 16px;
        }
      }

      @media screen and (max-width: 720px) {
        width: 49%;
      }
    }

    .first-casino-filter {
      @media screen and (max-width: 720px) {
        float: left;
      }

      .select-filter {
        @media screen and (max-width: 720px) {
          border-radius: 4px;
        }
      }
    }

    .second-casino-filter {
      @media screen and (max-width: 720px) {
        float: right;
      }
    }
  }
}