.copyright-ctn {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: var(--headerbg) !important;
  // border-top: 1px solid #454646;
  margin-left: 20px;
  margin-right: 20px;

  .brand {
    font-weight: bold;
  }

  span,
  div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 56px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: var(--text);
    opacity: .6;

    .copyright-link {
      color: var(--text);
      @media screen and (min-width: 920px) {
        padding-left: 6px;
      }
    }
    // color: rgba(255, 255, 255, 0.6) !important;
  }
}


@media screen and (max-width: 720px) {
  .rules-text {
    margin-bottom: 105px;
  }

  .d-block {
    display: block !important;
    text-align: center !important;
  }
  .copyright-ctn {
    justify-content: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 46px;
    padding-top: 12px;
  }

  .copyright-ctn,
  .copyright-ctn-light {
    span,
    div {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.mob-view-copy {
  height: 50px;
}