.notification-ctnn {
    height: 35px;
    background: var(--background-gradient);
    display: flex;
    justify-content: center;
    margin: 0 0 25px 0;
    width: 100%;

    @media screen and (max-width: 720px) {
        margin: 14px 0 14px 0;
        background: var(--background-gradient);
    }

    .notification-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #000000;

        @media screen and (max-width: 720px) {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
        }
    }
}