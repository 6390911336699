.mob-header {
  display: none;
}

.router-ctn {
  position: relative;

  @media (max-width: 1120px) {
    .web-header {
      display: none;
    }
    .mob-header {
      display: block;
    }
  }
}
.light,
.dark{
  --background: var(--home-page-bg), var(--ion-background) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.inner-scroll {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.mob-view-footer {
  display: block;
  // position: relative;
  // top: calc(100vh - 60px);
  // height: 60px;
}