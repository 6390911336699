.account-summary-ctn {
  position: relative;
  background-color: var(--background-image-color);
  margin-top: 58px;
  border-radius: 45px 45px 10px 10px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 24px;
  }

  .acc-statement-body .MuiButton-label, .MuiButton-label, .MuiButton-contained {
    background-color: transparent !important;
  }

  .img-page-title-row {
    display: inline-flex;
    align-items: center;
    height: 24px;
    margin-right: auto;
    background-color: var(--button);
    padding: 0 10px;
    border-radius: 0 8px 0 8px;
    margin: auto;
    z-index: 11;
    margin-top: 17px;
    margin-bottom: 24px;

    .title {
      color: #fff;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 10.3506px;
      line-height: 13px;
    }

    @media screen and (max-width: 720px) {
      margin-top: 12px;
    }
  }

  .title-border-line {
    height: 1px;
    width: 100%;
    background: var(--background-gradient);
    position: relative;
    top: -36px;
  }

  .account-ctn {
    // background: linear-gradient(182.19deg, #000000 1.84%, rgba(65, 65, 65, 0.96) 143.75%);
    border-radius: 6px;
    margin-bottom: 10px;
    // padding: 8px 8px;

    .account-card {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      background: linear-gradient(182.19deg, #000 1.84%, rgba(65, 65, 65, .96) 143.75%);
      padding: 8px;
      border-radius: 6px;
      position: relative;
    }

    div {
      font-family: 'Montserrat';
    }

    .closing-balance {
      flex: 1;
      padding: 13px 15px;
      background: #000000;
      border: 1px solid var(--button);
      border-radius: 6px;
      text-align: center;
      color: var(--button);
      margin-right: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      font-family: 'Poppins';
      min-width: 90px;
    }

    .transaction-info {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      align-items: center;
      display: flex;
      flex: 1;
      color: rgba(255, 255, 255, 0.8);
    }

    .transaction-date {
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.02em;
      align-items: center;
      display: flex;
      flex: 1;
      color: rgba(255, 255, 255, 0.6);
    }

    .account-info {
      flex: 6;
      display: flex;
      flex-direction: column;
    }
  }

  .select-filter {
    .select-filter-label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: var(--text);
      opacity: .6;
    }

    .select-filter-control {
      background-color: #3B3B3B;
      border: 0;
      height: 35px;
      width: 256.33px;

      @media screen and (max-width: 720px) {
        width: 100%;
        border-radius: 4px;
        height: 40px;
      }
    }
  }

  .filters-row {
    display: flex;
    margin-bottom: 16px;

    @media screen and (max-width: 720px) {
      display: block;

      .mr-11 {
        margin-right: 11px !important;
      }

      .date-section {
        margin-bottom: 10px;
      }
    }

    .date-section {
      display: flex;

      .date-filter {
        margin: 0 0 0 16px;

        @media screen and (max-width: 720px) {
          margin: 0;
        }

        .date-filter-label {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: var(--text);
          opacity: .6;
        }

        .MuiFormControl-marginNormal {
          margin-top: 0;
          margin-bottom: 0;
          background-color: #3B3B3B;

          @media screen and (max-width: 720px) {
            border-radius: 4px;
            height: 40px;

            .MuiInputBase-root {
              height: 40px !important;
            }
          }

          .MuiInputBase-root {
            border: 0;
            height: 35px;

            .MuiInputBase-input {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: rgba(255, 255, 255, 0.6);
            }

            .MuiSvgIcon-root {
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
    }

  }

  .MuiPaper-root {
    margin: 0px;
    border-radius: 3px !important;
    background-color: var(--market-table-row);
    position: relative;

    .select-filter-label {
      color: white;
    }

    .MuiTableHead-root {
      border-bottom: 1px solid var(--headerbg) !important;
      background: linear-gradient(180deg, #000000 -31.82%, #464646 143.18%);
      border-radius: 3px;
    }

    .MuiTableCell-head {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;

      color: rgba(255, 255, 255, 0.6) !important;

      @media screen and (max-width: 720px) {
        font-size: 10px;
        line-height: 12px;
        padding: 11px 5px;
      }
    }

    .MuiTable-root {
      .MuiTableHead-root .MuiTableRow-head {
        border-bottom: 1px solid var(--headerbg) !important;
        background-color: var(--headerbg) !important;
        color: var(--text) !important;

        .MuiTableCell-root,
        .MuiTableCell-head {
          background-color: var(--headerbg) !important;
          font-size: 10px !important;
          font-family: var(--font-family);
          color: #fff !important;
          padding: 3px !important;
        }
      }

      .MuiTableBody-root {
        font-size: 10px !important;
        background-color: var(--market-table-row) !important;

        .MuiTableRow-root {
          color: var(--text) !important;
          border-bottom: 1px solid var(--headerbg) !important;

          .MuiTableCell-body {
            color: #000 !important;
            font-size: 13px !important;
            padding: 12px !important;
            font-family: 'Montserrat';
          }
        }
      }
    }
  }
}

.account-summary-ctn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: var(--background-image-opacity);
  background-image: url('../../assets/images/home/homeview/background.svg');
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}