.mobile-sub-header.app-sub-header {
    margin: 0;
    padding: 0;
    padding-left: 10.5px;

    .actions-list {
        padding-left: 0;
        padding-right: 0;
    }
    .left-side {
        height: 35px;
        padding-top: 1px;
        .card {
            margin-right: 14px;

            &.active{
                .indian-svg {
                    opacity: 1;
                }    
                svg {
                    path {
                        fill: white;
                    }
                }
            }
            svg {
                margin-right: 4.5px;
            }

            .label-ctn {
                .label {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}

.search-bar {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: end;
    align-items: end;

    .ion-live-casino {
      position: fixed;
      width: 130px;
      font-size: 10px;
      background-color: #454646;
      z-index: 1111;
      top: 74px;
      background: linear-gradient(180deg, #000000 0%, #2A2A2B 100%);
      border: 1px solid var(--button);
      padding: 5px 15px;

      .ion-live-card {
        // border-top: 1px solid var(--text-primary);
        border-bottom: 1px solid var(--button);
        color: #f4f4f4;
        padding: 5px 0;
        cursor: pointer;
      }

      .ion-live-card:last-child {
        border-bottom: 0;
      }
    }

    .search-button {
      display: flex;
      align-items: center;
      padding-right: 12px;
      cursor: pointer;

      svg path {
        fill: var(--text);
      }
    }

    .search {
      display: flex;
      flex-direction: row;
      text-align: left;
      text-decoration: none;
      vertical-align: middle;
      user-select: none;
      height: 40px;
      background: var(--sub-header-btn-background);
      border: 1px solid var(--button);
      border-radius: 4px;

      @media screen and (max-width: 720px) {
        min-width: 100px;
        max-width: 131px;
        height: 24px;
      }

      text-transform: uppercase;
    }

    ::placeholder {
      font-size: 10px !important;
      // color: var(--text) !important;
      color: var(--sub-header-btn-text) !important;
      font-family: 'Inter';
      font-weight: 300 !important;
    }

    .input {
      border-right: none;
      outline: none;
      color: var(--sub-header-btn-text) !important;
      font-family: var(--font-family);
      border: 0px !important;
      background-color: var(--sub-header-btn-background) !important;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      width: 100%;
      border-radius: 4px;

      ::placeholder {
        font-family: 'Montserrat';
      }
    }

    .searchTerm:focus {
      color: var(--text) !important;
    }

    .searchButton {
      background-color: var(--active-link) !important;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0.275rem 1.2rem;

      @media screen and (max-width: 720px) {
        padding: 0.275rem 1.4rem;
      }

      width: 20px;
      background-image: url('../../assets/images/common/search.svg');
      background-position: center;
      background-repeat: no-repeat;
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }
  }